/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import { motion } from "framer-motion";
import img from './auto.jpg'
import  Line from './line.jpg'
import Cloud from './cl.jpg'

import { i } from 'framer-motion/client';
export default function Servie() {
  return (
    <div className='py-16 '>

<section className="bg-gray-100">
      <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8 lg:min-h-[60vh]">
          
          {/* Image Section */}
          <motion.div
            className="relative z-10 lg:py-12"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true, amount: 0.5 }} // Ensures animation triggers only once when half visible
          >
            <div className="relative h-64 sm:h-80 lg:h-full overflow-hidden">
              <img
                alt="Professional Image"
                src="https://www.hexapole.com/wp-content/uploads/2024/01/LabView-software-toegepast-voor-automatisering.webp"
                className="absolute border rounded-xl inset-0 h-full w-full object-cover transform transition-transform duration-700 ease-in-out hover:scale-105"
              />
            </div>
          </motion.div>

          {/* Text Section */}
          <motion.div
            className="relative flex items-center bg-white shadow-xl lg:min-h-[50vh]"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true, amount: 0.5 }} // Triggers when half the element is in view
          >
            <span className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-white"></span>
            <div className="p-4 sm:p-6 lg:p-16">
              <h2 className="text-xl font-bold sm:text-2xl text-[#003491] relative group">
                Process Automation with LabVIEW
                <span className="block h-[4px] bg-current scale-x-0 transition-transform duration-300 ease-out group-hover:scale-x-100 origin-left"></span>
              </h2>

              <p className="mt-4 text-gray-700 text-md leading-relaxed">
                Unlock the full potential of your industrial processes with our LabVIEW-based automation solutions. Our team designs, prototypes, and implements control systems that streamline operations, minimize downtime, and meet your specific needs.
              </p>

              <p className="mt-4 text-gray-700 text-sm leading-relaxed">
                "Enhance your industrial processes with tailored LabVIEW automation, driving efficiency and reducing operational downtime."
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>








    <section className="bg-gray-100">
      <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          
          {/* Text Section */}
          <motion.div
            className="relative flex items-center bg-[#ffffff] shadow-xl lg:min-h-[50vh] p-4 sm:p-6 lg:p-16"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <span className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-white"></span>
            <div>
              <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-[#003491] relative group">
                Test and Measurement with LabVIEW
                <span className="block h-[2px] bg-current scale-x-0 transition-transform duration-300 ease-out group-hover:scale-x-100 origin-left"></span>
              </h2>

              <p className="mt-4 text-gray-700 text-base sm:text-lg leading-relaxed">
                Achieve unparalleled precision and reliability with our LabVIEW-driven test and measurement solutions. Perfect for research, quality assurance, and production testing, our bespoke applications ensure accurate data capture and critical insights.
              </p>

              <p className="mt-4 text-gray-700 text-sm leading-relaxed">
                "Achieve precision in testing and measurement with our customized LabVIEW solutions."
              </p>
            </div>
          </motion.div>

          {/* Image Section */}
          <motion.div
            className="relative z-10 lg:py-12"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <div className="relative h-48 sm:h-64 lg:h-full overflow-hidden">
              <img
                alt="Professional Image"
                src="https://cf-images.us-east-1.prod.boltdns.net/v1/static/1326282160001/97509b42-0784-4ce8-bad4-3e497c2e846b/60546e36-accd-4b8e-9dcc-7b95344b2048/1280x720/match/image.jpg"
                className="absolute inset-0 h-full w-full object-cover transform transition duration-700 ease-in-out hover:scale-105"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>



    <section className="bg-gray-100">
      <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8 lg:min-h-[60vh]">
          
          {/* Image Section with Animation */}
          <motion.div
            className="relative z-10 lg:py-12"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <div className="relative h-64 sm:h-80 lg:h-full overflow-hidden">
              <img
                alt="Professional Image"
                src={Line}
                className="absolute border rounded-xl inset-0 h-full w-full object-cover transform transition-transform duration-700 ease-in-out hover:scale-105"
              />
            </div>
          </motion.div>

          {/* Text Section with Animation */}
          <motion.div
            className="relative flex items-center bg-white shadow-xl lg:min-h-[50vh]"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <span className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-white"></span>

            <div className="p-4 sm:p-6 lg:p-16">
              <h2 className="text-xl font-bold sm:text-2xl text-[#003491] relative group">
                Process Automation with PLC
                <span className="block h-[4px] bg-current scale-x-0 transition-transform duration-300 ease-out group-hover:scale-x-100 origin-left"></span>
              </h2>

              <p className="mt-4 text-gray-700 text-md leading-relaxed">
                Enhance your industrial operations with our expert PLC-based automation services. We design and implement robust, scalable, and reliable PLC systems to improve operational efficiency, safety, and performance.
              </p>

              <p className="mt-4 text-gray-700 text-sm leading-relaxed">
                "Optimize your industrial processes with scalable and reliable PLC automation."
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>



    <section className="bg-gray-100">
      <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          
          {/* Text Section with Animation */}
          <motion.div
            className="relative flex items-center bg-[#ffffff] shadow-xl lg:min-h-[50vh] p-4 sm:p-6 lg:p-16"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <span className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-white"></span>

            <div>
              <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-[#003491] relative group">
                IIOT Solutions
                <span className="block h-[2px] bg-current scale-x-0 transition-transform duration-300 ease-out group-hover:scale-x-100 origin-left"></span>
              </h2>

              <p className="mt-4 text-gray-700 text-base sm:text-lg leading-relaxed">
                Step into the future with our Industrial Internet of Things (IIOT) solutions. We integrate smart devices, sensors, and systems into a unified network, providing real-time data and analytics for remote monitoring, control, and optimization.
              </p>

              <p className="mt-4 text-gray-700 text-sm leading-relaxed">
                "Connect and optimize your industrial operations with our advanced IIOT solutions."
              </p>
            </div>
          </motion.div>

          {/* Image Section with Animation */}
          <motion.div
            className="relative z-10 lg:py-12"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <div className="relative h-48 sm:h-64 lg:h-full overflow-hidden">
              <img
                alt="Professional Image"
                src="https://images.squarespace-cdn.com/content/v1/55181a36e4b05c72e7f6a2a3/1644642964684-IFR844O0H5ODZMLIHES2/2-1-1.png?format=750w"
                className="absolute inset-0 h-full w-full object-cover transform transition duration-700 ease-in-out hover:scale-105"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>



    <section className="bg-gray-100">
      <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8 lg:min-h-[60vh]">
          
          {/* Image Section with Animation */}
          <motion.div
            className="relative z-10 lg:py-12"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <div className="relative h-64 sm:h-80 lg:h-full overflow-hidden">
              <img
                alt="Professional Image"
                src={Cloud}
                className="absolute border rounded-xl inset-0 h-full w-full object-cover transform transition-transform duration-700 ease-in-out hover:scale-105"
              />
            </div>
          </motion.div>

          {/* Text Section with Animation */}
          <motion.div
            className="relative flex items-center bg-white shadow-xl lg:min-h-[50vh]"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <span className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-white"></span>

            <div className="p-4 sm:p-6 lg:p-16">
              <h2 className="text-xl font-bold sm:text-2xl text-[#003491] relative group">
                Cloud-Based Plant and Process Monitoring
                <span className="block h-[4px] bg-current scale-x-0 transition-transform duration-300 ease-out group-hover:scale-x-100 origin-left"></span>
              </h2>

              <p className="mt-4 text-gray-700 text-md leading-relaxed">
                Ensure continuous operation and real-time oversight with our cloud-based monitoring solutions. Our secure cloud access provides transparency and quick responses to anomalies, reducing the need for extensive on-site infrastructure.
              </p>

              <p className="mt-4 text-gray-700 text-sm leading-relaxed">
                "Monitor and manage your processes anywhere, anytime with our cloud-based solutions."
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>



    <section className="bg-gray-100">
      <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">

          {/* Text Section with Animation */}
          <motion.div
            className="relative flex items-center bg-[#ffffff] shadow-xl lg:min-h-[50vh] p-4 sm:p-6 lg:p-16"
            initial={{ opacity: 0, x: -50 }} // Start animation from the left
            whileInView={{ opacity: 1, x: 0 }} // Slide into place
            transition={{ duration: 0.8, ease: 'easeOut' }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <span className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-white"></span>

            <div>
              <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-[#003491] relative group">
                Custom Test Rigs
                <span className="block h-[2px] bg-current scale-x-0 transition-transform duration-300 ease-out group-hover:scale-x-100 origin-left"></span>
              </h2>

              <p className="mt-4 text-gray-700 text-base sm:text-lg leading-relaxed">
                Immauto Services Inc. specializes in designing and manufacturing custom test rigs for various industries. Our test rigs are engineered for rigorous testing and validation, ensuring your products meet the highest quality and reliability standards.
              </p>

              <p className="mt-4 text-gray-700 text-sm leading-relaxed">
                "Rely on our custom test rigs for precision and reliability in your testing and validation processes."
              </p>
            </div>
          </motion.div>


          <motion.div
            className="relative z-10 lg:py-12"
            initial={{ opacity: 0, scale: 0.9 }}Start smaller with lower opacity
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <div className="relative h-48 sm:h-64 lg:h-full overflow-hidden">
              <img
                alt="Professional Image"
                src="https://www.trentonsystems.com/hs-fs/hubfs/metal%20test%20equipment.jpeg?width=1000&name=metal%20test%20equipment.jpeg"
                className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-700 ease-in-out hover:scale-105"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>

    </div>
  )
}
