import React, { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function Head() {

      const [scroll, setScroll] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
       <div className="relative h-screen overflow-hidden">
  <Swiper
    modules={[Pagination, Navigation, Autoplay]}
    spaceBetween={0}
    slidesPerView={1}
    autoplay={{ delay: 4000, disableOnInteraction: false }}
    onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
    className="h-full"
  >

    <SwiperSlide>
      <div
        className="h-full bg-cover bg-center relative"
        style={{ backgroundImage: `url('https://img.freepik.com/free-photo/industrial-robotic-arms-action-precision-technology_91128-4569.jpg')` }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative z-10 flex items-center justify-start h-full pl-10">
          <div className={`text-left space-y-4 ${activeSlide === 0 ? 'animate-slide-in' : ''}`}>
            <h2 className="text-5xl font-bold text-white">Immauto	Services	Inc.</h2>
            <p className="text-white mt-4 text-lg max-w-5xl">
            Driving	Efficiency,	Precision,	and	Reliability	Across	Industries
              
            </p>
            
            <div className='py-6'>
          <button class="  relative border hover:border-sky-600 duration-500 group cursor-pointer text-sky-50  overflow-hidden h-14 w-56 rounded-md bg-sky-800 p-2 flex justify-center items-center font-extrabold">
  <div class="absolute z-10 w-48 h-48 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-900 delay-150 group-hover:delay-75"></div>
  <div class="absolute z-10 w-40 h-40 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-800 delay-150 group-hover:delay-100"></div>
  <div class="absolute z-10 w-32 h-32 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-700 delay-150 group-hover:delay-150"></div>
  <div class="absolute z-10 w-24 h-24 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-600 delay-150 group-hover:delay-200"></div>
  <div class="absolute z-10 w-16 h-16 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-500 delay-150 group-hover:delay-300"></div>
  <p class="z-10">Discover More</p>
</button>
          </div> 



          </div>
        </div>
      </div>
    </SwiperSlide>

   
    <SwiperSlide>
      <div
        className="h-full bg-cover bg-center relative"
        style={{ backgroundImage: `url('https://img.freepik.com/free-photo/standard-quality-control-concept-m_23-2150041861.jpg?t=st=1725535613~exp=1725539213~hmac=4d7454a6811ac2bb1d9eae6159e5b6fd998b704f59aa7e6bb882cd69a61ba2fd&w=740')` }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative z-10 flex items-center justify-start h-full pl-10">
          <div className={`text-left space-y-4 ${activeSlide === 1 ? 'animate-slide-in' : ''}`}>
          <h2 className="text-5xl font-bold text-white">Immauto	Services	Inc.</h2>
            <p className="text-white text-lg max-w-5xl">
              Get real-time insights into your production process with cutting-edge monitoring systems. Make data-driven decisions that optimize performance and reduce downtime.
            </p>
          <div className='py-6'>
          <button class="  relative border hover:border-sky-600 duration-500 group cursor-pointer text-sky-50  overflow-hidden h-14 w-56 rounded-md bg-sky-800 p-2 flex justify-center items-center font-extrabold">
  <div class="absolute z-10 w-48 h-48 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-900 delay-150 group-hover:delay-75"></div>
  <div class="absolute z-10 w-40 h-40 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-800 delay-150 group-hover:delay-100"></div>
  <div class="absolute z-10 w-32 h-32 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-700 delay-150 group-hover:delay-150"></div>
  <div class="absolute z-10 w-24 h-24 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-600 delay-150 group-hover:delay-200"></div>
  <div class="absolute z-10 w-16 h-16 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-500 delay-150 group-hover:delay-300"></div>
  <p class="z-10">Discover More</p>
</button>
          </div> 




          </div>
        </div>
      </div>
    </SwiperSlide>
  </Swiper>


  <div className="absolute  right-10 top-1/2 transform -translate-y-1/2 z-20">
    {[0, 1].map((index) => (
      <div
        key={index}
        className={`h-10 w-1 rounded-xl bg-white mb-4 cursor-pointer transition-transform duration-300 ${
          activeSlide === index ? 'bg-[#173cf7] scale-x-150' : ''
        }`}
        onClick={() => handleSlideChange(index)}
      ></div>
    ))}
  </div>
</div>
      
    </div>
  )
}
