import React from "react";
import Navbar from "./components/Navbar";

import Footer from "./components/Footer";

import "./App.css";
import { Routes, Route } from "react-router-dom";
import About from "./components/Home/About";
import Servie from "./components/Home/Servie";
import Contact from "./components/Home/Contact";
import Home from "./components/Home/Home";
import Head from "./components/Home/Head";
const App = () => {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route index element={<Home/>} />
        {/* <Route path="/head" element={<Head/>} /> */}
          <Route path="/about" element={<About/>} />
          <Route path="/service" element={<Servie/>} />
          <Route path="/contact" element={<Contact/>} />
          
      </Routes>

   
      {/* <Product /> */}
      <Footer />
    </div>
  );
};

export default App;
