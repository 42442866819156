import React, { useState, useEffect } from 'react';
import img from './immauto.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function Navbar() {
  const [scroll, setScroll] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="relative">

      <nav
        className={`fixed top-0 w-full z-50 transition-all duration-300 ease-in-out ${
          scroll ? 'bg-white text-[#003491] ' : 'bg-white text-[#003491]'
        }`}
      >
     
        <div className="bg-[#003491] text-white py-2 sticky top-0 z-50">
          <div className="max-w-7xl mx-auto flex justify-between items-center px-4 sm:px-6 lg:px-8">
            <div className="text-sm">
              <span className="mr-4">
                info@immauto.com
              </span>
              
            </div>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noreferrer" className="hover:text-gray-300">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noreferrer" className="hover:text-gray-300">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.linkedin.com/company/immauto-services-inc/" target="_blank" rel="noreferrer" className="hover:text-gray-300">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noreferrer" className="hover:text-gray-300">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Main navbar */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <a href="/" className="flex items-center">
              <img src={img} alt="Logo" className="h-10 w-auto" />
            </a>

            {/* Hamburger Icon */}
            <div className="md:hidden flex items-center">
              <button onClick={toggleMenu} className="text-[#003491] hover:text-[#00286b] focus:outline-none">
                <i className={`fas fa-${menuOpen ? 'times' : 'bars'} fa-1x`}></i>
              </button>
            </div>

            {/* Navigation Links */}
            <div className="hidden md:flex items-center space-x-6">
              <a href="/" className="hover:text-[#00286b] transition duration-300">Home</a>
              <a href="/about" className="hover:text-[#00286b] transition duration-300">About</a>
              <a href="/service" className="hover:text-[#00286b] transition duration-300">Services</a>
              <a href="/contact" className="hover:text-[#00286b] px-3 py-2 rounded transition duration-300">Contact</a>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu Dropdown */}
      <div
  className={`fixed top-24 left-0 w-full bg-white shadow-lg z-40 transition-transform duration-300 ease-in-out ${
    menuOpen ? 'translate-y-0' : '-translate-y-full'
  }`}
>
  <div className="flex text-sm flex-col px-6 py-4 space-y-2">
    <a
      href="/"
      className="text-lg py-3 px-4 rounded-md hover:bg-[#00286b] hover:text-white hover:shadow-lg transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
      onClick={() => setMenuOpen(false)}
    >
      Home
    </a>
    <a
      href="/about"
      className="text-lg py-3 px-4 rounded-md hover:bg-[#00286b] hover:text-white hover:shadow-lg transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
      onClick={() => setMenuOpen(false)}
    >
      About
    </a>
    <a
      href="/services"
      className="text-lg py-3 px-4 rounded-md hover:bg-[#00286b] hover:text-white hover:shadow-lg transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
      onClick={() => setMenuOpen(false)}
    >
      Services
    </a>
    <a
      href="/contact"
      className="text-lg py-3 px-4 rounded-md hover:bg-[#00286b] hover:text-white hover:shadow-lg transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
      onClick={() => setMenuOpen(false)}
    >
      Contact
    </a>
  </div>
</div>

{/* 
      <div className="relative h-screen overflow-hidden">
  <Swiper
    modules={[Pagination, Navigation, Autoplay]}
    spaceBetween={0}
    slidesPerView={1}
    autoplay={{ delay: 4000, disableOnInteraction: false }}
    onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
    className="h-full"
  >

    <SwiperSlide>
      <div
        className="h-full bg-cover bg-center relative"
        style={{ backgroundImage: `url('https://img.freepik.com/free-photo/industrial-robotic-arms-action-precision-technology_91128-4569.jpg')` }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative z-10 flex items-center justify-start h-full pl-10">
          <div className={`text-left space-y-4 ${activeSlide === 0 ? 'animate-slide-in' : ''}`}>
            <h2 className="text-5xl font-bold text-white">Immauto	Services	Inc.</h2>
            <p className="text-white mt-4 text-lg max-w-5xl">
            Driving	Efficiency,	Precision,	and	Reliability	Across	Industries
              
            </p>
            
            <div className='py-6'>
          <button class="  relative border hover:border-sky-600 duration-500 group cursor-pointer text-sky-50  overflow-hidden h-14 w-56 rounded-md bg-sky-800 p-2 flex justify-center items-center font-extrabold">
  <div class="absolute z-10 w-48 h-48 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-900 delay-150 group-hover:delay-75"></div>
  <div class="absolute z-10 w-40 h-40 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-800 delay-150 group-hover:delay-100"></div>
  <div class="absolute z-10 w-32 h-32 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-700 delay-150 group-hover:delay-150"></div>
  <div class="absolute z-10 w-24 h-24 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-600 delay-150 group-hover:delay-200"></div>
  <div class="absolute z-10 w-16 h-16 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-500 delay-150 group-hover:delay-300"></div>
  <p class="z-10">Discover More</p>
</button>
          </div> 



          </div>
        </div>
      </div>
    </SwiperSlide>

   
    <SwiperSlide>
      <div
        className="h-full bg-cover bg-center relative"
        style={{ backgroundImage: `url('https://img.freepik.com/free-photo/standard-quality-control-concept-m_23-2150041861.jpg?t=st=1725535613~exp=1725539213~hmac=4d7454a6811ac2bb1d9eae6159e5b6fd998b704f59aa7e6bb882cd69a61ba2fd&w=740')` }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative z-10 flex items-center justify-start h-full pl-10">
          <div className={`text-left space-y-4 ${activeSlide === 1 ? 'animate-slide-in' : ''}`}>
          <h2 className="text-5xl font-bold text-white">Immauto	Services	Inc.</h2>
            <p className="text-white text-lg max-w-5xl">
              Get real-time insights into your production process with cutting-edge monitoring systems. Make data-driven decisions that optimize performance and reduce downtime.
            </p>
          <div className='py-6'>
          <button class="  relative border hover:border-sky-600 duration-500 group cursor-pointer text-sky-50  overflow-hidden h-14 w-56 rounded-md bg-sky-800 p-2 flex justify-center items-center font-extrabold">
  <div class="absolute z-10 w-48 h-48 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-900 delay-150 group-hover:delay-75"></div>
  <div class="absolute z-10 w-40 h-40 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-800 delay-150 group-hover:delay-100"></div>
  <div class="absolute z-10 w-32 h-32 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-700 delay-150 group-hover:delay-150"></div>
  <div class="absolute z-10 w-24 h-24 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-600 delay-150 group-hover:delay-200"></div>
  <div class="absolute z-10 w-16 h-16 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-sky-500 delay-150 group-hover:delay-300"></div>
  <p class="z-10">Discover More</p>
</button>
          </div> 




          </div>
        </div>
      </div>
    </SwiperSlide>
  </Swiper>


  <div className="absolute  right-10 top-1/2 transform -translate-y-1/2 z-20">
    {[0, 1].map((index) => (
      <div
        key={index}
        className={`h-10 w-1 rounded-xl bg-white mb-4 cursor-pointer transition-transform duration-300 ${
          activeSlide === index ? 'bg-[#173cf7] scale-x-150' : ''
        }`}
        onClick={() => handleSlideChange(index)}
      ></div>
    ))}
  </div>
</div> */}

    </div>
  );
}
