import React from 'react'
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import img from './driv.jpg'
export default function About() {
  const { ref: headingRef, inView: headingInView } = useInView({ triggerOnce: true });
  const { ref: imageRef, inView: imageInView } = useInView({ triggerOnce: true });
  const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true });
  return (
    <div>
 <section className='py-12'>
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-3xl">
          {/* Animate heading */}
          <motion.h2
            ref={headingRef}
            className="text-3xl font-bold sm:text-4xl"
            initial={{ opacity: 0, y: -50 }}
            animate={headingInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8 }}
          >
            Driving Efficiency, Precision, and Reliability Across Industries
          </motion.h2>
        </div>

        <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <motion.div
            ref={imageRef}
            className="relative h-64 overflow-hidden sm:h-80 lg:h-full"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={imageInView ? { opacity: 1, scale: 1 } : {}}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img
              alt="Innovation"
              src={img}
              className="absolute inset-0 h-full w-full rounded-xl object-cover"
            />
          </motion.div>

          <motion.div
            ref={textRef}
            className="lg:py-16"
            initial={{ opacity: 0, x: 50 }}
            animate={textInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <article className="space-y-4 text-gray-600">
              <motion.p
                className="text-lg"
                initial={{ opacity: 0 }}
                animate={textInView ? { opacity: 1 } : {}}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                At Immauto Services Inc., we specialize in delivering cutting-edge solutions that
                enhance efficiency, accuracy, and reliability across various industrial sectors.
              </motion.p>

              <motion.p
                className="text-lg"
                initial={{ opacity: 0 }}
                animate={textInView ? { opacity: 1 } : {}}
                transition={{ duration: 0.8, delay: 0.8 }}
              >
                Our innovative approaches empower businesses to optimize processes and achieve
                their goals with unparalleled precision and consistency.
              </motion.p>
            </article>
          </motion.div>
        </div>
      </div>
    </section>
    </div>
  )
}
