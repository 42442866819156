import React from 'react'
import Slider from './Slider'
// import Ban from '../../components/Ban'
import About from './About'
import Servie from './Servie'
import Contact from './Contact'
import News from './News'
import Head from './Head'
export default function Home() {
  return (
    <div>
           <Head/>
           <About/>
           <Servie/>
    
           {/* <News/> */}
                  <Contact/>
     
      
    
      
    </div>
  )
}
